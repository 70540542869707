import { navigate } from "gatsby"
import React, { useEffect } from "react"

import { useNotify } from "../../alert/hooks"
import AuthFormWrapper from "../../auth/components/AuthFormWrapper"
import AssociateForm from "../../auth/components/principal/sign-up/components/AssociateForm"
import PrincipalForm from "../../auth/components/principal/sign-up/components/PrincipalForm"
import Seo from "../../components/seo"
import { getUrlSearchParams } from "../../utils/navigation"

const SignUpPage: React.FC = () => {
  const isBrowser = typeof window !== "undefined"
  const notify = useNotify()
  const queryParams = getUrlSearchParams()

  const signInType =
    queryParams.principal_name && queryParams.invite_token
      ? "Associate"
      : !queryParams.principal_name && queryParams.invite_token
      ? "Principal"
      : undefined

  useEffect(() => {
    if (signInType == undefined) {
      notify("warning", "Invalid Sign Up URL", "please contact Yoco support")
      if (isBrowser) {
        navigate("/sign-in/")
      }
    }
  }, [signInType, isBrowser, notify])

  return (
    <AuthFormWrapper
      title={
        signInType === "Principal"
          ? "Let's make you an official Yoco Principal"
          : `${
              queryParams.principal_name
                ? queryParams.principal_name
                : "A Principal"
            } invited you to become a Yoco Reseller`
      }
    >
      <Seo title="Yoco - Sign up" />
      {signInType === "Associate" ? (
        <AssociateForm
          inviteToken={(queryParams.invite_token as string) || ""}
          emailAddress={(queryParams.email as string) || ""}
        />
      ) : signInType === "Principal" ? (
        <PrincipalForm
          inviteToken={(queryParams.invite_token as string) || ""}
          emailAddress={(queryParams.principal_email as string) || ""}
        />
      ) : (
        <p>Invalid Sign Up URL, redirecting...</p>
      )}
    </AuthFormWrapper>
  )
}

export default SignUpPage
