import { Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Button,
  Password,
  Input,
  LabeledLink,
  RadioToggleField,
  CheckboxInput,
  Checkbox,
} from "../../../../../components"
import { usePrincipalRegister, useNextUrl } from "../../../../hooks"
import {
  validateEmail,
  validateIdentity,
  validateNewPassword,
  validatePhone,
  validateBusinessRegistration,
  validateFirstName,
  validateLastName,
  validateVatNumber,
} from "../../../../validationObjects"
import IdentificationInputGroup from "../../../IdentificationInputGroup"

const FormContainer = styled.div`
  .field-header {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .flex {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .half {
      width: auto;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      .feid-header {
        text-align: left;
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      .half {
        width: 50%;
      }
    }
  }

  .link {
    color: ${({ theme }) => theme.palette.outrageousOrange};
    text-decoration: underline;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  p {
    font-size: 15px;
    margin: 0;
  }
`

const validationSchema = Yup.object()
  .shape(
    {
      firstName: validateFirstName,
      lastName: validateLastName,
      email: validateEmail,
      phoneNumber: validatePhone,
      password: validateNewPassword,
      businessType: Yup.string(),
      businessName: Yup.string().required("Business name required"),
      vatNumber: validateVatNumber,
      companyRegistration: Yup.string().when("businessType", {
        is: (businessType: string) => businessType === "CK_REGISTERED",
        then: validateBusinessRegistration,
        otherwise: Yup.string(),
      }),
      consent: Yup.bool()
        .oneOf([true], "You must accept the terms and conditions")
        .required("Accept terms and conditions"),
    },
    [["businessType", "companyRegistration"]]
  )
  .concat(validateIdentity)

const toggleFormOptions = [
  {
    id: "soleProprietorship",
    label: "Sole proprietorship",
    value: "SOLE_PROPRIETORSHIP",
    defaultChecked: false,
  },
  {
    id: "ckRegistered",
    label: "CK registered business",
    value: "CK_REGISTERED",
    defaultChecked: true,
  },
]

interface Props {
  inviteToken: string
  emailAddress: string
}

const PrincipalForm: React.FC<Props> = ({ inviteToken, emailAddress }) => {
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    idNumber: "",
    passportNumber: "",
    phoneNumber: "",
    password: "",
    businessType:
      toggleFormOptions.find((option) => option.defaultChecked === true)
        ?.value || "",
    businessName: "",
    vatNumber: "",
    companyRegistration: "",
    consent: false,
  }
  const principalRegister = usePrincipalRegister()
  const nextUrl = useNextUrl()
  const [vatInputShowing, setVatInputShowing] = useState(false)

  const showVatInput = (e: any) => {
    setVatInputShowing(e.target.checked)
  }

  defaultValues.email = emailAddress ? emailAddress : ""

  return (
    <FormContainer>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const success = await principalRegister({
              ...values,
              inviteToken: inviteToken,
            })
            if (success) {
              nextUrl("/dashboard/")
            }
          } catch (err) {
            console.warn(err)
          }
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="half">
                <Input placeholder="First Name *" name="firstName" />
              </div>
              <div className="half">
                <Input placeholder="Last Name *" name="lastName" />
              </div>
            </div>
            <Input placeholder="Email *" name="email" type="email" />
            <IdentificationInputGroup
              idAdded={!!values.idNumber}
              passportAdded={!!values.passportNumber}
            />
            <Input
              placeholder="Phone Number *"
              name="phoneNumber"
              additionalLabel="+27"
            />
            <Password placeholder="Password *" name="password" />
            <p className="field-header">Select your type of business</p>
            <RadioToggleField
              name="businessType"
              options={toggleFormOptions}
              size="small"
              padded
              OnRadioToggle={() => {
                if (values.businessType === "CK_REGISTERED") {
                  setFieldValue("companyRegistration", "", false)
                  setFieldTouched("companyRegistration", false)
                }
              }}
            />
            <Input placeholder="Enter Business Name *" name="businessName" />
            <CheckboxContainer>
              <Checkbox
                id="vat-registered"
                name="vat-registered"
                isChecked={vatInputShowing}
                toggleCheckCallback={showVatInput}
              />
              <p>Business is VAT registered</p>
            </CheckboxContainer>
            {vatInputShowing ? (
              <Input placeholder="Enter VAT Number" name="vatNumber" />
            ) : null}
            <Input
              placeholder="Enter Company Registration Number *"
              name="companyRegistration"
              disabled={values.businessType !== "CK_REGISTERED"}
            />
            <CheckboxInput id="principal-consent" name="consent">
              <span>
                I consent to Yoco processing my personal information for the
                purpose of an application for a certified Yoco Reseller
                including background and criminal checks.
              </span>
            </CheckboxInput>
            <Button
              label="Submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              color="#018567"
              textColor="white"
            />
            <LabeledLink
              label="Already a Yoco Principal?"
              linkText="Login"
              linkPath="/sign-in/"
            />
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export default PrincipalForm
