import React from "react"
import styled from "styled-components"

import Input from "../../components/Input"
import { InputContainer, InputLabel } from "../../components/Input/index"

const Container = styled.div<{
  flexDirection: "vertical" | "horizontal"
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 2px;

  & > * {
    flex: 1;
  }

  .or-divider {
    flex: 0;
    margin-top: 0px;
    margin-bottom: 4px;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: ${({ flexDirection }) =>
      flexDirection === "horizontal" ? "row" : "column"};
    align-items: ${({ flexDirection }) =>
      flexDirection === "horizontal" ? "flex-start" : "stretch"};
    gap: ${({ flexDirection }) =>
      flexDirection === "horizontal" ? "5px" : "2px"};

    .or-divider {
      margin-top: ${({ flexDirection }) =>
        flexDirection === "horizontal" ? "20px" : "0px"};
    }
  }
`

interface Props {
  IdCypressTestId?: string
  PassportCypressTestId?: string
  orientation?: "vertical" | "horizontal"
  idAdded: boolean
  passportAdded: boolean
  label?: string
}

const IdentificationInputGroup: React.FC<Props> = ({
  IdCypressTestId,
  PassportCypressTestId,
  orientation = "vertical",
  idAdded,
  passportAdded,
  label,
}) => {
  return (
    <InputContainer margin="0px">
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Container flexDirection={orientation}>
        <Input
          cypressTestId={IdCypressTestId}
          placeholder="South African ID Number"
          name="idNumber"
          disabled={passportAdded}
        />
        <p className="or-divider">OR</p>
        <Input
          cypressTestId={PassportCypressTestId}
          placeholder="SA or Foreign Passport"
          name="passportNumber"
          disabled={idAdded}
        />
      </Container>
    </InputContainer>
  )
}

export default IdentificationInputGroup
