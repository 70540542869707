import { Formik } from "formik"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Button,
  Password,
  Input,
  LabeledLink,
  CheckboxInput,
} from "../../../../../components"
import { useAssociateRegister, useNextUrl } from "../../../../hooks"
import {
  validateEmail,
  validateIdentity,
  validateNewPassword,
  validatePhone,
  validateFirstName,
  validateLastName,
} from "../../../../validationObjects"
import IdentificationInputGroup from "../../../IdentificationInputGroup"

const FormContainer = styled.div`
  .flex {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    .half {
      width: auto;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      .half {
        width: 50%;
      }
    }
  }

  .link {
    color: ${({ theme }) => theme.palette.outrageousOrange};
    text-decoration: underline;
  }
`

const validationSchema = Yup.object({
  firstName: validateFirstName,
  lastName: validateLastName,
  email: validateEmail,
  phoneNumber: validatePhone,
  password: validateNewPassword,
  consent: Yup.bool()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Accept terms and conditions"),
}).concat(validateIdentity)

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  idNumber: "",
  passportNumber: "",
  phoneNumber: "",
  password: "",
  consent: false,
}

interface Props {
  inviteToken: string
  emailAddress: string
}

const AssociateForm: React.FC<Props> = ({ inviteToken, emailAddress }) => {
  const associateRegister = useAssociateRegister()
  const nextUrl = useNextUrl()

  defaultValues.email = emailAddress ? emailAddress : ""

  return (
    <FormContainer>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const success = await associateRegister({
              ...values,
              inviteToken: inviteToken,
            })
            if (success) {
              nextUrl("/dashboard/")
            }
          } catch (err) {
            console.warn(err)
          }
        }}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="half">
                <Input placeholder="First Name *" name="firstName" />
              </div>
              <div className="half">
                <Input placeholder="Last Name *" name="lastName" />
              </div>
            </div>
            <Input placeholder="Email *" name="email" type="email" />
            <IdentificationInputGroup
              idAdded={!!values.idNumber}
              passportAdded={!!values.passportNumber}
            />
            <Input
              placeholder="Phone Number *"
              name="phoneNumber"
              additionalLabel="+27"
            />
            <Password placeholder="Password *" name="password" />
            <CheckboxInput id="associate-consent" name="consent">
              <span>
                I consent to Yoco processing my personal information for the
                purpose of an application for a certified Yoco Reseller
                including background and criminal checks.
              </span>
            </CheckboxInput>
            <Button
              label="Submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              color="#018567"
              textColor="white"
            />
            <LabeledLink
              label="Already a Yoco Principal?"
              linkText="Login"
              linkPath="/sign-in/"
            />
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AssociateForm
